import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import styled, { useTheme } from "styled-components";
import { Close20StrokedIcon } from "wint-icons";
import { DOWNLOAD_APP_CARD_LINKS } from "@common/constants/download_app";
import useLockBodyScroll from "@common/hooks/useLockBodyScroll";
import { Flex } from "@common/styles";
import { BodyMediumText, HeadingSerifMediumText } from "@common/styles/typographies";
import { getImagesCdnUrl } from "@common/utils/env";
import Portal from "../Portal";
type DownloadAppModalProps = {
  show: boolean;
  onClose?: () => void;
  title?: string;
  description?: string;
  qrImageUrl?: string;
};
const reveal = {
  hidden: {
    y: "8px",
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeOut"
    }
  },
  visible: {
    y: "0",
    opacity: 1
  },
  exit: {
    y: "8px",
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn"
    }
  }
};
const imagePath = `${getImagesCdnUrl()}/webDS/icons`;
const DownloadAppModal: React.FC<DownloadAppModalProps> = ({
  show,
  onClose,
  title = "Download Wint Wealth mobile app",
  qrImageUrl = DOWNLOAD_APP_CARD_LINKS?.qrImage
}) => {
  const theme = useTheme();
  useLockBodyScroll({
    isLocked: show
  });
  return <Portal data-sentry-element="Portal" data-sentry-component="DownloadAppModal" data-sentry-source-file="DownloadAppModal.tsx">
			<AnimatePresence initial={false} mode="wait" onExitComplete={() => null} data-sentry-element="AnimatePresence" data-sentry-source-file="DownloadAppModal.tsx">
				{show ? <Overlay initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} exit={{
        opacity: 0
      }} transition={{
        duration: 0.3
      }} onClick={onClose}>
						<Container variants={reveal} initial="hidden" animate="visible" exit="exit" onClick={(e: React.MouseEvent) => e.stopPropagation()}>
							<Cross onClick={onClose} />
							<Image src={qrImageUrl} width={244} height={244} alt="qr-code" />
							<TextContainer>
								<HeadingSerifMediumText>{title}</HeadingSerifMediumText>
								<BodyMediumText $webColor={theme?.colors.text.tertiary}>
									Scan the code to download the app
								</BodyMediumText>
							</TextContainer>
							<Flex $alignItems="center" $gap="9px">
								<BodyMediumText $webColor={theme?.colors.text.tertiary}>
									Available on{" "}
								</BodyMediumText>
								<Image alt="google-play" src={`${imagePath}/GooglePlayIcon.svg`} width={14} height={14} />
								<AppleLogo alt="google-play" src={`${imagePath}/AppleLogo.svg`} width={24} height={24} />
							</Flex>
						</Container>
					</Overlay> : null}
			</AnimatePresence>
		</Portal>;
};
export default DownloadAppModal;
const Overlay = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({
  theme
}) => theme.colors.bg.overlay.backdrop};
	z-index: 99999999999;
`;
const Container = styled(motion.div)`
	width: 488px;
	height: fit-content;
	padding: 74px 40px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	border-radius: 4px;
	background: ${({
  theme
}) => theme.colors.bg.section.default};
	box-shadow: 0px 21px 30.7px 0px rgba(255, 255, 255, 0.02);
`;
const TextContainer = styled.div`
	padding: 50px 0px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;
`;
const AppleLogo = styled(Image)`
	box-sizing: initial;
	padding: 0px 0px 0px 4px;
	border-left: 1px solid
		${({
  theme
}) => theme.colors.stroke.divider.emphasized};
`;
const Cross = styled(Close20StrokedIcon)`
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
	color: ${({
  theme
}) => theme.colors.text.primary};
`;